import { defineStore } from 'pinia'
import { useUserStore } from './user'
import type { WorkspaceSummary } from '~/types/Workspace'
import { useWorkspaceStore } from '~/stores/workspace'

export const useUserWorkspacesStore = defineStore('userWorkspaces', () => {
  // OTHER STORES ========================================================================
  const userStore = useUserStore()
  const workspaceStore = useWorkspaceStore()

  // STATE ========================================================================
  const recentWorkspaceIds = useLocalStorage<string[]>('recentWorkspaceIds', [])

  watch(
    () => workspaceStore.currentWorkspaceId,
    (newId) => {
      if (!newId) return

      if (typeof newId !== 'string') return

      unshiftRecentWorkspaceId(newId)
    },
  )

  watch(
    () => userStore.user?.workspaces,
    (newWorkspaces) => {
      if (!newWorkspaces || newWorkspaces.length === 0) return

      recentWorkspaceIds.value = recentWorkspaceIds.value.filter((wsId) =>
        newWorkspaces.some((ws) => ws.id === wsId),
      )
    },
    {
      deep: true,
    },
  )

  const userWorkspaces = computed(() => {
    if (!userStore.user) return []

    const output: WorkspaceSummary[] = []

    recentWorkspaceIds.value.forEach((id) => {
      const workspace = userStore.user!.workspaces.find(
        (ws: WorkspaceSummary) => ws.id === id,
      )
      if (workspace) output.push(workspace)
    })

    output.push(
      ...userStore
        .user!.workspaces.filter(
          (ws: WorkspaceSummary) => !recentWorkspaceIds.value.includes(ws.id),
        )
        .sort((a: WorkspaceSummary, b: WorkspaceSummary) =>
          a.title.localeCompare(b.title),
        ),
    )

    return output
  })

  function unshiftRecentWorkspaceId(workspaceId: string) {
    recentWorkspaceIds.value = recentWorkspaceIds.value.filter(
      (id) => id !== workspaceId,
    )
    recentWorkspaceIds.value.unshift(workspaceId)
  }

  return {
    userWorkspaces,
    recentWorkspaceIds,
  }
})
